<template lang="pug">
  div
    slot(name="activator" :toggle="toggle")
      v-btn(
        small
        color='primary'
        @click="toggle"
        :disabled="disabledAccept"
        outlined
      ) Mark as processed
    confirmation-dialog(
      v-model="showing"
      :title="modalTitle"
      :descr="modalText"
      @okBtnClicked="markAsProcessed"
    )
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    activeItems: {
      required: true,
      type: Array
    },
    disabled: Boolean
  },

  data: () => ({
    showing: false,
    modalTitle: 'Mark as Processed',
    modalText: 'Are you sure you want to mark as processed this Candidates?'
  }),

  computed: {
    disabledAccept() {
      if (this.disabled) return true;
      return !this.activeItems.length;
    },
  },

  inject: ['svc'],

  methods: {
    toggle() {
      if (this.disabledAccept) return;
      this.showing = !this.showing;
    },

    markAsProcessed() {
      this.toggle();
      this.mark();
    },

    async mark() {
      try {
        let ids = this.activeItems.map(item => item.ID);
        await this.svc().markAsProcessed(ids);
        this.$notify({ text: 'Mark as processed', type: 'success' });
        this.$emit('clearSelection');
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM });
      }
    },
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
  }
}
</script>
